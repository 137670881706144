import { render, staticRenderFns } from "./HosipitalAsset.vue?vue&type=template&id=7579ba26&scoped=true"
import script from "./HosipitalAsset.vue?vue&type=script&lang=js"
export * from "./HosipitalAsset.vue?vue&type=script&lang=js"
import style0 from "./HosipitalAsset.vue?vue&type=style&index=0&id=7579ba26&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7579ba26",
  null
  
)

export default component.exports